import type { User } from '@dreamshaper/ds-interface'
import { useDSInterface } from './useDSInterface'
import type { Token } from '@/types/Token'

export const useAuth = () => {
  const { auth, user, org, rememberUserAuth } = useUser()

  const dsInterface = useDSInterface()

  const signIn = async (email: string, password: string, rememberMe = false): Promise<{ user?: User, statusCode?: number, message?: string, withoutRoles?: boolean } | null> => {
    const response = await dsInterface.auth().legacy({
      email,
      password,
    })

    if (!response.data?.user) {
      if (response.error) {
        return { message: response.error[0].message, statusCode: response.statusCode }
      }

      return null
    }

    auth.value = {
      jwt: response.data.jwt,
      recover: response.data.recover,
    }

    dsInterface.setInstance({
      ...dsInterface.instanceConfig,
      jwt: auth.value?.jwt || undefined,
      jwtRecover: auth.value?.recover || undefined,
    })

    const { data: userData, error: userError, statusCode: userStatusCode } = await dsInterface.user().findOne({
      userId: response.data.user.uuid,
    })

    if (userError && userStatusCode !== 200) {
      return { message: userError[0].message, statusCode: userStatusCode }
    }

    const allowedRoles = ['ROLE_SUPER_ADMIN', 'ROLE_PRODUCT_ADMIN', 'ROLE_OPS_ADMIN']

    if (!userData?.roles?.some(role => allowedRoles.includes(role))) {
      return { withoutRoles: true }
    }

    if (userData) {
      user.value = {
        ...userData,
        active_projects: null,
        last_activities: null,
      }
    }

    rememberUserAuth.value = rememberMe

    return { user: userData }
  }

  const signOut = async () => {
    auth.value = null
    user.value = null
    org.value = null

    navigateTo('/login')
  }

  const check = async (token: Token) => {
    const response = await dsInterface.auth().check({ jwt: token.jwt || auth.value?.jwt || '' })

    return response.statusCode === 204
  }

  const recover = async (token: Token) => {
    const response = await dsInterface.auth().recover({
      jwt: token.jwt || auth.value?.jwt || '',
      recover: token.recover || auth.value?.recover || '',
    })

    if (!response.data?.user) {
      return null
    }

    auth.value = {
      jwt: response.data.jwt,
      recover: response.data.recover,
    }

    dsInterface.setInstance({
      ...dsInterface.instanceConfig,
      jwt: auth.value?.jwt || undefined,
      jwtRecover: auth.value?.recover || undefined,
    })

    const userResponse = await dsInterface.user().findOne({
      userId: response.data.user.uuid,
    })

    if (userResponse.data) {
      user.value = userResponse.data
    }

    return user.value
  }

  return {
    signIn,
    signOut,
    check,
    recover,
  }
}
